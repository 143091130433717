import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from "react-router-dom";
import { routes } from './utils/routes';
import { useTranslation } from "react-i18next";

// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

export default function App() { // Neccessity because of useLocation
    return (
      <Router>
        <AppContent />
      </Router>
    );
}
  
function AppContent() {
    const location = useLocation();
    const { i18n } = useTranslation();
  
    useEffect(() => {
      const lang = location.pathname.split('/')[1];
      if (lang && ['en', 'pl'].includes(lang)) {
        i18n.changeLanguage(lang);
      } else {
        i18n.changeLanguage('en'); // Default language
      }
    }, [location, i18n]);
  
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>   
            <Route path="/" element={<Navigate to="/en" replace />} />
            {routes.map(({ path, Component }) => (
                <Route key={path} path={path} element={<Component />} />
            ))} 
        </Routes>
      </Suspense>
    );
}