import React from 'react';

export const routes = [
    // English Routes
    {
        path: '/en',
        name: 'About Us',
        Component: React.lazy(() => import('../pages/Home/Home')),
    },
    {
        path: '/en/catalogue',
        name: 'Products',
        Component: React.lazy(() => import('../pages/Catalogue/Catalogue')),
    },
    {
        path: '/en/contact',
        name: 'Contact',
        Component: React.lazy(() => import('../pages/Contact/Contact')),
    },
    {
        path: '/en/product/:id',
        name: 'Product',
        Component: React.lazy(() => import('../pages/Product/Product')),
    },
    {
        path: '/en/product/:id/customize/samples',
        name: 'Customizer',
        Component: React.lazy(() => import('../pages/Customizer/Customizer')),
    },
    {
        path: '/en/product/:id/customize/offer',
        name: 'Customizer',
        Component: React.lazy(() => import('../pages/Customizer/Customizer')),
    },
    
    // Polish Routes
    {
        path: '/pl',
        name: 'O Nas',
        Component: React.lazy(() => import('../pages/Home/Home')),
    },
    {
        path: '/pl/katalog',
        name: 'Produkty',
        Component: React.lazy(() => import('../pages/Catalogue/Catalogue')),
    },
    {
        path: '/pl/kontakt',
        name: 'Kontakt',
        Component: React.lazy(() => import('../pages/Contact/Contact')),
    },
    {
        path: '/pl/produkt/:id',
        name: 'Produkt',
        Component: React.lazy(() => import('../pages/Product/Product')),
    },
    {
        path: '/pl/produkt/:id/konfiguracja/probki',
        name: 'Konfigurator',
        Component: React.lazy(() => import('../pages/Customizer/Customizer')),
    },
    {
        path: '/pl/produkt/:id/konfiguracja/oferta',
        name: 'Konfigurator',
        Component: React.lazy(() => import('../pages/Customizer/Customizer')),
    },
    // Uncomment and modify the NoPage route if needed
    // {
    //     path: '/*',
    //     name: 'NoPage',
    //     Component: React.lazy(() => import('../pages/NoPage/NoPage')),
    // },
];